import { Container, Drawer, useMantineTheme } from '@mantine/core'
import React from 'react'

export const Popover: React.FC<{
  children: React.ReactElement | React.ReactElement[]
  opened: boolean
  toggle: () => void
}> = ({ children, toggle, opened }) => {
  const {
    other: { spacing, corners },
    radius,
  } = useMantineTheme()
  return (
    <Drawer
      opened={opened}
      onClose={toggle}
      position="bottom"
      size="calc(100% - 55px)"
      styles={{
        header: {
          margin: 0,
          padding: 0,
        },
        drawer: {
          borderRadius: `${radius.sm}px ${radius.sm}px 0 0`,
          ...corners.top.lg,
        },
        closeButton: {
          width: '60px',
          height: '60px',
          color: 'black',
        },
      }}
    >
      <div style={{ maxHeight: '100%', overflowY: 'auto', paddingBottom: '4rem' }}>
        <Container sx={{ padding: `0px ${spacing(6)}px ${spacing(24)}px ${spacing(6)}px` }}>{children}</Container>
      </div>
    </Drawer>
  )
}
