import { Center, Container, DefaultProps, useMantineTheme } from '@mantine/core'
import { ReactElement } from 'react'

export const ScreenContainer: React.FC<{
  children: ReactElement | ReactElement[]
  space?: number | string
  center?: boolean
  sx?: DefaultProps<never>['sx']
}> = ({ children, space, center, sx }) => {
  const {
    other: { spacing },
  } = useMantineTheme()
  return (
    <>
      {center ? (
        <Center
          sx={sx}
          style={{
            width: '100vw',
            height: '100vh',
            padding: space || spacing(6),
            margin: 0,
          }}
        >
          {children}
        </Center>
      ) : (
        <Container
          sx={{
            width: '100vw',
            height: '100vh',
            padding: space || spacing(6),
            margin: 0,
          }}
        >
          {children}
        </Container>
      )}
    </>
  )
}
