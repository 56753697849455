import { TenantCodePage } from 'pages/TenantCodePage/TenantCodePage'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './styles/fonts.css'
import './styles/styles.css'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<TenantCodePage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App
