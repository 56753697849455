import { ActionIconStylesNames, ButtonStylesParams, InputStylesParams, MantineProviderProps, MantineTheme, NotificationStylesParams } from '@mantine/core'
import { MantineThemeOverride } from '@mantine/styles'
import { match } from 'ts-pattern'
import { globalStyles, globalTheme } from './globalStyles'

type ObjStyleToArr = [
  (string | undefined)?,
  (string | undefined)?,
  (string | undefined)?,
  (string | undefined)?,
  (string | undefined)?,
  (string | undefined)?,
  (string | undefined)?,
  (string | undefined)?,
  (string | undefined)?,
  (string | undefined)?
]

const objStyleToMantineArr = <T extends object | string>(obj: T) =>
  typeof obj === 'string'
    ? [obj]
    : (Object.values(obj)
        .reduce((prev, curr) => [...prev, curr], [])
        .slice(0, 9) as ObjStyleToArr)

const globalStylesEntityToMantine = <T extends object>(obj: T) =>
  Object.entries(globalTheme.colors).reduce(
    (prev, [key, value]) => ({
      ...prev,
      [key]: objStyleToMantineArr(value),
    }),
    {}
  )

export const cuttedCorners = {
  top: {
    md: { clipPath: `polygon(4px 0, 0 4px, 0 100%, 0 100%, 100% 100%, 100% 100%, 100% 4px, calc(100% - 4px) 0)` },
    lg: { clipPath: `polygon(8px 0, 0 8px, 0 100%, 0 100%, 100% 100%, 100% 100%, 100% 8px, calc(100% - 8px) 0)` },
  },
  all: {
    md: { clipPath: `polygon(4px 0, 0 4px, 0 calc(100% - 4px), 4px 100%, calc(100% - 4px) 100%, 100% calc(100% - 4px), 100% 4px, calc(100% - 4px) 0)` },
    lg: { clipPath: `polygon(8px 0, 0 8px, 0 calc(100% - 8px), 8px 100%, calc(100% - 8px) 100%, 100% calc(100% - 8px), 100% 8px, calc(100% - 8px) 0)` },
  },
}

export const theme: MantineThemeOverride = {
  fontFamily: globalTheme.fonts.body,
  fontSizes: {
    xs: globalTheme.fontSizes[1],
    sm: globalTheme.fontSizes[2],
    md: globalTheme.fontSizes[3],
    lg: globalTheme.fontSizes[4],
    xl: globalTheme.fontSizes[5],
  },
  headings: {
    fontFamily: globalTheme.fonts.heading,
    fontWeight: 400,
    sizes: {
      h1: globalStyles.h1,
      h2: globalStyles.h2,
      h3: globalStyles.h3,
    },
  },
  primaryColor: 'lime',
  primaryShade: 5,
  colors: globalStylesEntityToMantine(globalTheme.colors),
  spacing: {
    xs: globalTheme.spaces(4),
    sm: globalTheme.spaces(5),
    md: globalTheme.spaces(6),
    lg: globalTheme.spaces(7),
    xl: globalTheme.spaces(8),
  },
  lineHeight: globalTheme.lineHeights['125'],
  other: {
    spacing: globalTheme.spaces,
    corners: cuttedCorners,
  },
}

export const componentStyles: MantineProviderProps['styles'] = {
  Button: (theme: MantineTheme, params: ButtonStylesParams) => ({
    root: {
      ...theme.other.corners.all.md,
      height: 64,
      padding: '0 24px',
      color: theme.colors.black,
      fontWeight: 400,
      width: '100%',
      ...match(params.color)
        .with('primary', () => ({
          backgroundColor: theme.colors.lime[5],
          color: theme.colors.black,
          '&:active': {
            backgroundColor: theme.fn.darken(theme.colors.lime[5], 0.05),
          },
        }))
        .with('secondary', () => ({
          backgroundColor: theme.colors.grays[3],
          color: theme.colors.black,
          '&:active': {
            backgroundColor: theme.fn.darken(theme.colors.grays[3], 0.05),
          },
        }))
        .with('white', () => ({
          backgroundColor: theme.colors.grays[0],
          color: theme.colors.black,
          '&:active': {
            backgroundColor: theme.fn.darken(theme.colors.grays[0], 0.02),
          },
        }))
        .with('black', () => ({
          backgroundColor: theme.colors.grays[7],
          color: theme.colors.white,
          '&:active': {
            backgroundColor: theme.colors.grays[7],
          },
          '&:disabled': {
            backgroundColor: `${theme.colors.grays[1]} !important`,
          },
          '&::before': {
            backgroundColor: 'transparent',
          },
        }))
        .otherwise(() => ({})),

      '&:disabled .mantine-Button-label': {
        color: theme.colors.grays[5],
      },
      '&:disabled.mantine-Button-loading': {
        backgroundColor: `${theme.colors.black} !important`,
      },
      '&:disabled.mantine-Button-loading .mantine-Button-label': {
        color: theme.colors.white,
      },
    },
  }),
  Input: (theme: MantineTheme, params: InputStylesParams) => ({
    /* input: {
      padding: `${theme.spacing.md}px ${theme.spacing.xs}px`,
      borderRadius: 0,
      lineHeight: theme.lineHeight,
      ...(params.invalid
        ? {
            backgroundColor: theme.colors.error[0],
            borderColor: theme.fn.lighten(theme.colors.error[1], 0.25),
            '&:focus': {
              borderColor: theme.colors.error[1],
              color: theme.colors.black,
            },
          }
        : {}),
    }, */
    input: {
      padding: `${theme.spacing.md}px ${theme.spacing.xs}px`,
      borderRadius: 0,
      borderColor: theme.colors.grays[6],
      lineHeight: theme.lineHeight,
      '&:focus': {
        borderColor: '#000',
        color: theme.colors.black,
      },
      ...(params.invalid
        ? {
            backgroundColor: `${theme.colors.error[0]} !important`,
            borderColor: `${theme.colors.error[1]} !important`,
            color: `${theme.colors.black} !important`,
          }
        : {}),
    },
  }),
  Notification: (theme: MantineTheme, params: NotificationStylesParams) => ({
    root: {
      '&::before': {
        display: 'none',
      },
      padding: `${theme.spacing.xs}px ${theme.spacing.sm}px`,
      top: theme.spacing.lg,
      position: 'absolute',
      zIndex: 2,
      ...(params.color === 'error'
        ? {
            backgroundColor: theme.colors.error[0],
            border: `2px solid ${theme.colors.error[1]}`,
            borderLeft: `0px`,
            borderRight: `0px`,
            borderRadius: 0,
            width: '100%',
            textAlign: 'center',
          }
        : {}),
    },
    title: {
      ...(params.color === 'error'
        ? {
            color: `${theme.colors.error[1]}`,
          }
        : {}),
    },
    closeButton: {
      ...(params.color === 'error'
        ? {
            color: `${theme.colors.error[1]}`,
          }
        : {}),
    },
  }),
  ActionIcon: (theme: MantineTheme, params: ActionIconStylesNames) => ({
    root: {
      ...theme.other.corners.all.md,
      background: 'transparent',
    },
  }),
}
