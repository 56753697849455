import { Notification, Stack, Text, useMantineTheme } from '@mantine/core'
import { useTranslation } from 'react-i18next'

export const ErrorNotification = ({ type, closeButton = false }: { type: 'no-service' | 'system-error'; closeButton?: boolean }) => {
  const {
    other: { spacing },
  } = useMantineTheme()
  const { t } = useTranslation()
  return (
    <>
      <Notification
        color="error"
        disallowClose={!closeButton}
        title={
          <Stack spacing={spacing(1)}>
            {type === 'no-service' ? (
              <>
                <Text size="sm" weight={600}>
                  {t('error_coverage1')}
                </Text>
                <Text size="sm" weight={400}>
                  {t('error_coverage2')}
                </Text>
              </>
            ) : (
              <>
                <Text size="sm" weight={600}>
                  {t('error_system1')}
                </Text>
                <Text size="sm" weight={400}>
                  {t('error_system2')}
                </Text>
              </>
            )}
          </Stack>
        }
      />
    </>
  )
}
