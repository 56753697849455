/* Global Styles Theme */

export const globalTheme = {
  borders: {},
  borderStyles: {},
  borderWidths: {},
  colors: {
    primary: '#E7EB5D',
    secondary: '#E9D7C9',
    lightGray: '#F7F2F2',
    lime: {
      default: '#E7EB5D',
      1: '#FAFBDF',
      2: '#F7F8C9',
      3: '#F3F5AE',
      4: '#EFF293',
      5: '#EBEE78',
      6: '#E7EB5D',
      7: '#C0C44E',
      8: '#9A9D3E',
      9: '#74762F',
      10: '#4D4E1F',
      11: '#2E2F13',
    },
    carrot: {
      default: '#FF8736',
      1: '#FFE7D7',
      2: '#FFD7BC',
      3: '#FFC39A',
      4: '#FFAF79',
      5: '#FF9B58',
      6: '#FF8736',
      7: '#D4712D',
      8: '#AA5A24',
      9: '#80441B',
      10: '#552D12',
      11: '#331B0B',
    },
    fucales: {
      default: '#72723B',
      1: '#E3E3D8',
      2: '#D0D0BE',
      3: '#B8B89D',
      4: '#A1A17C',
      5: '#89895C',
      6: '#72723B',
      7: '#5F5F31',
      8: '#4C4C27',
      9: '#39391E',
      10: '#262614',
      11: '#17170C',
    },
    beige: {
      default: '#E9D7C9',
      1: '#FBF7F4',
      2: '#F8F2ED',
      3: '#F4EBE4',
      4: '#F0E4DB',
      5: '#EDDED2',
      6: '#E9D7C9',
      7: '#C2B3A7',
      8: '#9B8F86',
      9: '#756C65',
      10: '#4E4843',
      11: '#2F2B28',
    },
    grays: {
      default: '#e0e0e0',
      1: '#FFFFFF',
      2: '#F8F1F1',
      3: '#EEE7E7',
      4: '#DAD3D3',
      5: '#B2ABAB',
      6: '#948D8D',
      7: '#6F6C6C',
      8: '#000000',
    },
    black: '#000000',
    white: '#ffffff',
    error: {
      1: '#FFE5D4',
      2: '#B73A23',
    },
    message: {
      1: '#FFE5E5',
      2: '#00D600',
    },
    hairline: 'rgba(0,0,0,0.12)',
  },
  fonts: {
    body: "'DMSans', Arial, sans-serif",
    heading: "'Signifier', Arial, sans-serif",
  },
  fontSizes: [11, 14, 16, 18, 20, 28, 36, 48],
  //fontWeights: {},
  letterSpacings: {},
  lineHeights: {
    135: '135%',
    125: '125%',
    110: '110%',
  },
  opacities: {},
  radii: {},
  shadows: {},
  spaces: (space: number) => Math.floor(space) * 4,
  transitions: {},
  zIndices: {},
}

const texts = {
  fontFamily: globalTheme.fonts.body,
}

const headings = {
  fontFamily: globalTheme.fonts.heading,
  lineHeight: globalTheme.lineHeights[110],
}

export const globalStyles = {
  text: {
    default: {
      ...texts,
      fontSize: globalTheme.fontSizes[2],
      lineHeight: globalTheme.lineHeights[135],
    },
    xxs: {
      fontSize: globalTheme.fontSizes[0],
      lineHeight: globalTheme.lineHeights[135],
    },
    xs: {
      fontSize: globalTheme.fontSizes[1],
      lineHeight: globalTheme.lineHeights[135],
    },
    sm: {
      fontSize: globalTheme.fontSizes[2],
      lineHeight: globalTheme.lineHeights[135],
    },
    md: {
      fontSize: globalTheme.fontSizes[3],
      lineHeight: globalTheme.lineHeights[135],
    },
    lg: {
      fontSize: globalTheme.fontSizes[4],
      lineHeight: globalTheme.lineHeights[125],
    },
    xl: {
      fontSize: globalTheme.fontSizes[5],
      lineHeight: globalTheme.lineHeights[125],
    },
  },
  a: {},
  h1: {
    ...headings,
    fontSize: globalTheme.fontSizes[7],
  },
  h2: {
    ...headings,
    fontSize: globalTheme.fontSizes[6],
  },
  h3: {
    ...headings,
    fontSize: globalTheme.fontSizes[5],
  },
  img: {},
  pre: {},
  code: {},
  ol: {},
  ul: {},
  li: {},
  blockquote: {},
  hr: {},
  em: {},
  table: {},
  tr: {},
  th: {},
  td: {},
  strong: {},
  del: {},
  b: {},
  i: {},
}
